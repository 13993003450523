<template>
<div class="container1150">
<header><strong>EV-QD声学多普勒流量计</strong></header>
<main>

<div class="img1"></div>
<h2>产品特点</h2>
<p>● · 断面流速测量</p>
<p>
                ·
                超声波换能器的测量截面为椭圆形，覆盖不同纵深的流速，更接近于断面流速
            </p>
<p>● · 高测量精度</p>
<p>· 不规则断面水动力模型于流量智能积分算法保证流量测量精度</p>
<p>● · 低功耗设计</p>
<p>
                ·
                嵌入式软硬件整体低功耗设计、先进的电源管理策略及科学的工作切换模式
            </p>
<p>● · 独特结构设计</p>
<p>· 通过专利保护的结构设计，有效减少流量计的维护工作</p>
<div class="img2"></div>
<div class="img3"></div>
<div class="img4"></div>
<div class="img5"></div>
</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            // width: 100%;
            width: 550px;
            height: 400px;
            margin: 10px auto;
            background-image: url("../../../assets/img/product/liuliangji.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img2 {
            width: 900px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/9-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img3 {
            width: 1100px;
            height: 350px;
            margin: 10px auto;
            background-image: url("/img/product/9-2.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 900px;
            height: 350px;
            margin: 10px auto;
            background-image: url("/img/product/9-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 900px;
            height: 450px;
            margin: 10px auto;
            background-image: url("/img/product/9-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>